<template>
  <Container>
    <PinterestGallery :header-text="headline" :gallery-url="galleryUrl" />
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      headline() {
        return this.getValue("headline")
      },
      galleryUrl() {
        return this.getValue("gallery_url")
      },
    },
  }
</script>
