<template>
  <div>
    <Headline :level="3">
      {{ headerText }}
    </Headline>
    <div class="pinterest-container">
      <a
        data-pin-do="embedBoard"
        :data-pin-board-width="871"
        :data-pin-scale-height="180"
        :data-pin-scale-width="60"
        :href="galleryUrl"
      >
        Pinterest Gallery
      </a>
    </div>
  </div>
</template>

<script>
  import Headline from "~/components/Headline"
  import loadExternalScript from "~/mixins/loadExternalScript"

  export default {
    components: { Headline },
    mixins: [loadExternalScript],
    props: {
      headerText: {
        type: String,
        required: true,
      },
      galleryUrl: {
        type: String,
        required: true,
      },
    },
    async mounted() {
      await this.loadExternalScript("//assets.pinterest.com/js/pinit.js")
    },
  }
</script>

<style lang="scss" scoped>
  @import "assets/styles";

  .lead {
    @apply text-lg leading-normal;
    text-align: left;
    margin: auto;
    margin-bottom: $base-spacing * 7;
  }
  .pinterest-container > :first-child {
    display: block;
    max-width: 100%;
  }
</style>
